import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UIReportType } from '~/types/reportType';
import { MockDataFile } from '~/types/mockDataFile';
import { useInjectable } from '~/hooks/useInjectable';
import { IMocksServiceKey } from '~/services/mocks-service.interfaces';

export function useStaticDataStore(
  reportType: UIReportType
) {
  return defineStore(`staticDataStore: ${reportType}`, () => {
    const files = ref<MockDataFile[]>([]);

    const mocksService = useInjectable(IMocksServiceKey);

    async function update() {
      files.value = mocksService.getMockFiles(reportType);
    }

    if (process.server)
      useLazyAsyncData(() => update());

    return { files, update };
  })();
}
