<script setup lang="ts">
import { ReportUtils } from '~/utils/reportUtils';
import { MockDataFile } from '~/types/mockDataFile';
import { useStaticDataStore } from '~/stores/staticDataStore';
import { useDataStore } from '~/stores/dataStore';

const reportType = computed(() => ReportUtils.getCurrentUIReportType());
const staticDataStore = computed(() => (reportType.value != undefined ? useStaticDataStore(reportType.value) : undefined));
const dataStore = computed(() => (reportType.value != undefined ? useDataStore(reportType.value) : undefined));

const files = computed<MockDataFile[]>(() => staticDataStore.value?.files ?? new Array<MockDataFile>());
const hasFiles = computed(() => files.value.length > 0);

function onSelectChange(e: Event) {
  const target = e.target as HTMLSelectElement;
  const { value } = target;

  if (value != undefined) {
    const dataStoreInstance = dataStore.value;

    if (dataStoreInstance != undefined)
      dataStoreInstance.loadMockFileById(value);
  }
}

watch(staticDataStore, (store) => {
  store?.update();
});
</script>

<template>
  <div class="existing-mock-data-selector">
    <div v-if="hasFiles" class="existing-mock-data-selector__content">
      <div>
        Данные:
      </div>

      <select @change="onSelectChange">
        <option>Выберите файл</option>
        <option v-for="staticDataFile in files" :value="staticDataFile.name">
          {{ staticDataFile.name }}
        </option>
      </select>
    </div>

    <div v-else class="existing-mock-data-selector__no-content-placeholder">
      Нет доступных данных
    </div>
  </div>
</template>

<style lang="scss">
  .existing-mock-data-selector__content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
</style>
