<template>
  <input ref="inputRef" type="file" @change="onInputChange">
</template>

<script setup lang="ts">
  import {useDataStore} from "~/stores/dataStore";
  import {ReportUtils} from "~/utils/reportUtils";
  
  const inputRef = ref();

  const reportType = computed(() => ReportUtils.getCurrentUIReportType());
  const dataStore = computed(() => reportType.value != undefined ? useDataStore(reportType.value) : undefined);

  const onInputChange = async (e: Event) => {
    const dataStoreInstance = dataStore.value;
    
    if(dataStoreInstance != undefined) {
      const target = <HTMLInputElement>e.currentTarget;
      const files = target.files as FileList;

      if (files.length > 0) {
        const file = files[0];
        
        await dataStoreInstance.loadFileAsync(file);
      }
    }
  }
  
  watch(reportType, () => {
    inputRef.value.value = '';    
  })
</script>

<style lang="scss">

</style>