import { ReportUtils } from '~/utils/reportUtils';
import { UIReportType } from '~/types/reportType';
import { NavigationLink } from '~/types/navigationLink';

export const adminLinks: Array<NavigationLink> = [
  {
    title: 'Задачи',
    route: '/admin/async-pdf/tasks'
  }
];

export const reportLinks: Array<NavigationLink> = [
  {
    title: 'Компании',
    route: ReportUtils.getReportRoute(UIReportType.ul)
  },
  {
    title: 'Персоны',
    route: ReportUtils.getReportRoute(UIReportType.fl)
  },
  {
    title: 'Номера телефонов',
    route: ReportUtils.getReportRoute(UIReportType.phoneNumber)
  },
  {
    title: 'Автомобили',
    route: ReportUtils.getReportRoute(UIReportType.vehicle)
  }
];
