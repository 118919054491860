<script setup lang="ts">
import CustomMockLoader from './CustomMockLoader.vue';
import ExistingMockDataSelector from './ExistingMockDataSelector.vue';

  enum MockDataLoaderView {
    existingMocks = 0,
    customMock = 1
  }

const view = ref(MockDataLoaderView.existingMocks);
const isExistingMocksView = computed(() => view.value == MockDataLoaderView.existingMocks);
const isCustomMockView = computed(() => view.value == MockDataLoaderView.customMock);

const toggleButtonLabel = computed(() => {
  switch (view.value) {
    case MockDataLoaderView.existingMocks: return 'Загрузить данные из файла';
    case MockDataLoaderView.customMock: return 'Выбрать данные из списка';
    default: return '';
  }
});

function toggleView() {
  switch (view.value) {
    case MockDataLoaderView.existingMocks:
      view.value = MockDataLoaderView.customMock;
      break;

    default:
      view.value = MockDataLoaderView.existingMocks;
      break;
  }
}
</script>

<template>
  <div class="mock-data-loader">
    <VButton @click="toggleView">
      {{ toggleButtonLabel }}
    </VButton>
    <ExistingMockDataSelector v-if="isExistingMocksView" />
    <CustomMockLoader v-if="isCustomMockView" />
  </div>
</template>

<style lang="scss">
  .mock-data-loader {
    display: flex;
    align-items: center;
    gap: 30px;
  }
</style>
