<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePreloaderStore } from '~/modules/loaders/preloader/stores/preloaderStore';

const preloaderStore = usePreloaderStore();

const { showPreloader } = storeToRefs(preloaderStore);
const { isWebView } = useViewModeClass();

const hasPreloader = computed(() => showPreloader.value && isWebView);
</script>

<template>
  <ClientOnly>
    <Transition>
      <div v-if="hasPreloader" class="ui-preloader-page">
        <IconBaseContainer
          common-size="64"
          class="ui-preloader-page__loader"
        >
          <ReportGenerationLoaderBold />
        </IconBaseContainer>
      </div>
    </Transition>
  </ClientOnly>
</template>

<style lang="scss">
.ui-preloader-page {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: var(--main-report-bg);
  background-size: 100%;
  //backdrop-filter: $blur-32;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-position-preloader;

  .ui-preloader-page__loader {
    animation: spinner 2.5s linear infinite;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
