<script setup lang="ts">
import { NavigationLink } from '~/types/navigationLink';
import { adminLinks, reportLinks } from '~/configuration/navigationLinks';

const router = useRouter();

function handleNavigation(link: NavigationLink) {
  router.push({
    path: link.route,
    query: { devtools: 1 }
  });
}
</script>

<template>
  <div class="navigation">
    <VDropdown
      placeholder="Admin"
      :items="adminLinks"
      @select="handleNavigation"
    />

    <VDropdown
      placeholder="Reports"
      :items="reportLinks"
      @select="handleNavigation"
    />
  </div>
</template>

<style lang="scss">
.navigation {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
